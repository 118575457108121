span.ant-radio-inner {
  position: absolute;
  top: 25px;
}

h4.gx-rgt-txt {
  margin-top: 20px !important;
}

.summaryForm {
  height: 182px !important;
  background: #001a39a3 !important;
}

.overylayGray {
  background-color: #80808061 !important;
}

.position-top-right {
  top: 0 !important;
  left: 80% !important;
  background: #ffffff00 !important;
}

.appointment-cell {
  height: 220px !important;
}

.availability-infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
  height: 300px;
  text-align: left;
}

.availability-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}

.gx-boxView {
  width: 85px !important;
}

.gx-changeboxView {
  width: 85px !important;
}

.service-list {
  overflow: auto;
  height: 400px;
}

.gx-contact-item {
  align-items: end !important;
}

.gx-contact-list-info .gx-email {
  word-break: unset !important;
}
